export const ProductImage = ({ src }: { src: string }) => {
  return (
    <div className="h-full w-full flex items-center">
      <img
        src={src}
        className="sm:w-full sm:px-6 object-contain transform -rotate-90 sm:rotate-0"
      />
    </div>
  );
};
