export const KEYMAP_EDITOR_URL = "/editor/index.html"

// Incoming
// --------
// these enums are sent from vue
// make sure they're in sync
export enum KeyboardId {
    BT60_V1_HOTSWAP = 'bt60v1_hotswap',
    BT60_V1_ANSI = "bt60v1_ansi",
    BT60_V1_ISO = "bt60v1_iso",
    BT60_V1_TSANGAN = "bt60v1_tsangan",
    BT60_V1_1U = "bt60v1_1u",

    BT60_V2_ANSI = "bt60v2_ansi",
    BT60_V2_ISO = "bt60v2_iso",
    BT60_V2_TSANGAN = "bt60v2_tsangan",
    BT60_V2_1U = "bt60v2_1u",

    BT65_V1_ANSI = "bt65v1_ansi",
    BT65_V1_ISO = "bt65v1_iso",
    BT65_V1_TSANGAN = "bt65v1_tsangan",
    BT65_V1_1U = "bt65v1_1u",

    BT75_V1_ANSI = "bt75v1_ansi",
    BT75_V1_ISO = "bt75v1_iso",
    // BT75_V1_TSANGAN = "bt75v1_tsangan", // no tsangan support right now
    BT75_V1_1U = "bt75v1_1u",
}

export const getKeyboardParamsFromId = (id: KeyboardId) => {
    switch (id) {
        case KeyboardId.BT60_V1_ANSI:
            return { keyboard: Keyboard.BT60V1, layout: Layout.ANSI }
        case KeyboardId.BT60_V1_ISO:
            return { keyboard: Keyboard.BT60V1, layout: Layout.ISO }
        case KeyboardId.BT60_V1_TSANGAN:
            return { keyboard: Keyboard.BT60V1, layout: Layout.TSANGAN }
        case KeyboardId.BT60_V1_1U:
            return { keyboard: Keyboard.BT60V1, layout: Layout["1U"] }

        case KeyboardId.BT60_V2_ANSI:
            return { keyboard: Keyboard.BT60V2, layout: Layout.ANSI }
        case KeyboardId.BT60_V2_ISO:
            return { keyboard: Keyboard.BT60V2, layout: Layout.ISO }
        case KeyboardId.BT60_V2_TSANGAN:
            return { keyboard: Keyboard.BT60V2, layout: Layout.TSANGAN }
        case KeyboardId.BT60_V2_1U:
            return { keyboard: Keyboard.BT60V2, layout: Layout["1U"] }

        case KeyboardId.BT65_V1_ANSI:
            return { keyboard: Keyboard.BT65V1, layout: Layout.ANSI }
        case KeyboardId.BT65_V1_ISO:
            return { keyboard: Keyboard.BT65V1, layout: Layout.ISO }
        case KeyboardId.BT65_V1_TSANGAN:
            return { keyboard: Keyboard.BT65V1, layout: Layout.TSANGAN }
        case KeyboardId.BT65_V1_1U:
            return { keyboard: Keyboard.BT65V1, layout: Layout["1U"] }

        case KeyboardId.BT75_V1_ANSI:
            return { keyboard: Keyboard.BT75V1, layout: Layout.ANSI }
        case KeyboardId.BT75_V1_ISO:
            return { keyboard: Keyboard.BT75V1, layout: Layout.ISO }
        // case KeyboardId.BT75_V1_TSANGAN:
        //     return { keyboard: Keyboard.BT75V1, layout: Layout.TSANGAN }
        case KeyboardId.BT75_V1_1U:
            return { keyboard: Keyboard.BT75V1, layout: Layout["1U"] }

        // this is @legacy, props are not used
        case KeyboardId.BT60_V1_HOTSWAP:
            return { keyboard: Keyboard.BT60V1, layout: Layout.ANSI }
        default:
            return null
    }
}
export type KeyboardParams = ReturnType<typeof getKeyboardParamsFromId>

// these correspond to the JSON keys
// make sure they're in sync
export enum Keyboard {
    BT60V1 = "bt60_v1",
    BT60V2 = "bt60_v2",
    BT65V1 = "bt65_v1",
    BT75V1 = "bt75_v1"
}

export enum Layout {
    ANSI = "ANSI",
    ISO = "ISO",
    TSANGAN = "TSANGAN",
    "1U" = "1U",
}

// JSON
// this is what comes back after selecting the keyboard and layout
export interface KeyboardData {
    keymap: ZMKKeymap;
    layout: any[]
}

interface ZMKKeymap {
    keyboard: string;
    'pw-kbid'?: string; // this is the additional identifier on top of zmk
    keymap: string,
    layout: string,
    layer_names: string[];
    layers: string[][]
}
