import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React from "react";

interface SceneProps {
  camera?: {
    position?: [number, number, number];
    fov?: number;
    zoom?: number;
  };
  scene?: {
    fog?: {
      near?: number;
      far?: number;
    };
  };
  controls?: {
    limit?: {
      left?: number;
      right?: number;
      up?: number;
      down?: number;
      near?: number;
      far?: number;
    };
  };
  pannable?: boolean;
}

const getAngle = (num: number) => Math.PI * num;
const neg = (num?: number) => (num ? -num : num!);
export const Scene3D: React.FC<React.PropsWithChildren<SceneProps>> = ({
  children,
  camera,
  pannable,
  controls,
}) => {
  return (
    <div style={{ height: "100%", minHeight: 600, position: "relative" }}>
      <Canvas
        shadows
        dpr={[0.5, 1]}
        camera={{
          position: camera?.position || [0, 0, 10],
          fov: camera?.fov || 20,
          zoom: camera?.zoom,
        }}
      >
        <ambientLight intensity={0.8} />
        {/* <spotLight
          intensity={0.6}
          angle={0.1}
          penumbra={1}
          position={[10, 15, 10]}
          castShadow
        /> */}

        {children}

        <OrbitControls
          enablePan={pannable || false}
          autoRotate
          autoRotateSpeed={0.3}
          maxAzimuthAngle={
            controls?.limit?.right
              ? getAngle(controls?.limit?.right)
              : undefined
          } // right
          minAzimuthAngle={
            controls?.limit?.left
              ? getAngle(neg(controls!.limit!.left))
              : undefined
          } // left
          minDistance={controls?.limit?.near || 2}
          maxDistance={controls?.limit?.far || 7}
          maxPolarAngle={getAngle(controls?.limit?.down || 0.4)} // down
          minPolarAngle={getAngle(controls?.limit?.up || 0.4)} // up
        />
        {/* <color attach="background" args={["white"]} /> */}
        {/* <fog
          color={"white" as unknown as THREE.Color}
          attach="fog"
          near={scene?.fog?.near || 8}
          far={scene?.fog?.far || 30}
        /> */}
      </Canvas>
    </div>
  );
};
