import Link from "next/link";

interface Props {
  href?: string;
  label: string | JSX.Element;
  variant?: Variant;
}

export enum Variant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export const Button: React.FC<Props> = ({
  href,
  label,
  variant = Variant.PRIMARY,
}) => {
  const variants = {
    [Variant.PRIMARY]:
      "bg-gray-900 text-white border-2 border-gray-900 hover:bg-gray-800 hover:border-gray-800",
    [Variant.SECONDARY]:
      "bg-white border-2 border-gray-900 text-grey-700 hover:bg-gray-100",
  };
  return (
    <Link href={href || "#"}>
      <a
        className={`
        px-5 py-3 
        font-bold tracking-wide 
        rounded-md cursor-pointer font-title
        ${href ? variants[variant] : "pointer-events-none bg-gray-300"}
      `}
      >
        {label}
      </a>
    </Link>
  );
};
