import React from "react";
import { Button, Variant } from "../components/Button";
import { ProductImage } from "../components/ProductImage";
import { RotrScene } from "../products/rotr/RotrScene";
import { RotrLiteScene } from "../products/rotrLite/RotrLiteScene";
import { KEYMAP_EDITOR_URL } from "./api";
import { INTEREST_CHECK_FORM } from "./urls";

// FIXME: REMEMBER, JAVASCRIPT MONTHS ARE INDEXES FROM 0!!!!!!!! therefore 12 means next year jan, and 11 means dec!

interface Timeline {
  date: Date;
  title: string;
  description?: JSX.Element | string;
}

export interface Data {
  title: string;
  description: {
    long: string;
    short?: string;
  };
  externalUrl?: string;
  images: string[];
  model: JSX.Element;
  timeline: Timeline[];
  url: string;
  videoUrl?: {
    desktop: string;
    mobile: string;
  };
  card?: {
    additionalContent: JSX.Element;
  };
  page?: {
    // extra stuff for BT60 and BTCKP
    button?: JSX.Element;
    content?: JSX.Element;
  };
}

/**
 * ensure the string matches exactly the folder name in public
 */
export enum Product {
  ROTR = "rotr",
  ROTR_LITE = "rotrLite",
  CRBN = "crbn",
  BT60 = "bt60",
  BTCKP = "btckp",
  // CRBN60 = "crbn60",
  SOON = "soon",
}

const augmentImagePath = (product: Product) => (filename: string) =>
  `/products/${product}/images/${filename}.jpg`;

export const DATA: { [key in Product]: Data } = {
  [Product.ROTR]: {
    title: "ROTR",
    description: {
      long: "After almost 6 months of development and prototyping, the ROTR has arrived. The 3 man team at polarity works have been working almost non stop to make this the last macro pad with a knob that you’ll ever need. Drop some switches into the minimalist anodized aluminium housing, connect the USB C connector, and the ultra-bright RGB lights blaze into life and you’re ready to go!",
    },
    images: ["01", "02", "03", "04", "05", "06", "07"].map(
      augmentImagePath(Product.ROTR)
    ),
    model: <RotrScene />,
    timeline: [
      {
        date: new Date(2020, 5, 1),
        title: "IC",
        description: "",
      },
      {
        date: new Date(2020, 6, 16),
        title: "prototype 001 - cnc machining complete",
        description:
          "The CNC company is done machining our initial prototypes, they just need to be anodized before they're ready! Check out our Discord for more updates like these!",
      },
      {
        date: new Date(2020, 6, 21),
        title: "prototype 001 - anodization complete",
        description:
          "The anodizing is now done, it's looking pretty good! There was a slight mix up and the CNC company accidentally made the knobs in copper instead of brass. What do you guys think of the sandblasted copper knob?",
      },
      {
        date: new Date(2020, 8, 5),
        title: "prototype 001 - inspection and fitment test",
        description: (
          <div>
            Hey everyone! Sorry for the lack of updates lately, my package with
            the prototype parts got held up at customs. Now that I have my hands
            of them I am very pleased to say that everything works and it looks
            great! The PCB took a bit of fiddling with to get working but after
            a few bodge wires, it fired into life. Unfortunately, under the
            closer inspection of the CNC parts, we found many defects and
            problems that just couldn't be overlooked. The majority of these
            were cosmetic problems with the parts, things like deep tool marks
            on the knobs and cases or less than brilliant tolerances. In short,
            we no longer feel that the CNC company that made the prototypes can
            be trusted with large scale production and we are looking at another
            company. This will set the schedule back a bit but should ensure
            that the products that get to you guys are of high quality. We are
            also taking this time to try a new way of mounting the knobs that
            will let them be interchangeable, here's a video of a 3d printed
            prototype spinning!
            https://drive.google.com/file/d/1SAGFUmZ65JX3VOrx6Qc7cpZHrHblcji6/view?usp=sharing
            We know that not all of you want the knob to spin quite as much so
            we are also working on a way that will let you tune how much
            resistance there is on the knob. We'll keep you updated on this but
            I can promise you that it's very cool! There are also a few other
            projects that we have been working on during all of this waiting
            around, keep an eye out for them in the next few weeks! I am also
            very excited to announce that the vendor for the ROTR will be
            Mechboards! And finally, we want to thank you all for the huge
            amount of interest and support you have shown for this project. I
            would have never been possible if not for you guys!
          </div>
        ),
      },
      {
        date: new Date(2020, 11, 11),
        title: "GB - Start",
        description: (
          <div>
            After almost 6 months of development and prototyping the ROTR has
            arrived. The 3 man team at polarity works have been working almost
            non stop to make this the last macro pad with knob you’ll ever need.
            Drop some switches into the minimalist anodized aluminium housing,
            connect the usb C connector and the ultra bright RGB lights blaze
            into life and you’re ready to go! After 4 revisions the knob
            mechanism has been perfected, using a pair of Japanese NMB bearings
            to float a precision machined steel shaft underneath a beautifully
            anodized and tactile knob. Underneath the shaft sits a magnet and
            under that some air. The only contact between the knob and the shaft
            is through the bearings. Unlike our cheaper competitors this means
            there is no notchiness, no clicks and almost no resistance. You can
            spin this knob by blowing on it gently! If greater resistance is
            desired there is the provision to put magnets in the knob to allow
            for eddy current braking. This has previously been used on Japanese
            shinkansen and German ICE3 high speed trains. The resistance can be
            varied through 12 levels by varying the position of the magnets, and
            it doesn’t make a difference to the silky smooth nature of the
            mechanism. The knob on the ROTR is swappable, its non-contact
            mechanism meaning you can just pull the magnetically attached knob
            off and snap on a new one. We custom machined a tapered precision
            interface, the design of which we will release to the community to
            allow for third party knobs. We will offer replacement knobs made
            out of aluminium, brass and copper so you can make your ROTR fit
            your chosen aesthetic The ROTR is fully programmable, using QMK
            firmware you can set up a wonderful combination of layers and macros
            to your heart’s content. The profile is online on the QMK
            configurator so making tweaks trivial and allows for an endless
            amount of customisation, volume knob, scrolling through the timeline
            in editing software or a cad controller, the options are limitless.
            If you decide that even that is not enough we broke out 8 I/O pins,
            the programming header and the led data out pin, allowing for
            hacking and modification to truly make it your own, add extra
            buttons, a second encoder, RGB underglow. It’s all possible without
            tricky surface mount soldering. We would also like to introduce it's
            baby brother, the ROTR LITE, with the same ludicrously
            over-engineered encoder! the ROTR LITE has identical pcb and knob
            mechanism as the ROTR, but no aluminium case. This means eddy
            current braking would not exist (as there is no aluminium to create
            those eddy currents).
          </div>
        ),
      },
      {
        date: new Date(2021, 0, 11),
        title: "GB - end",
        description: "",
      },
      {
        date: new Date(2021, 3, 1),
        title: "Production - Start",
        description: "",
      },
      {
        date: new Date(2021, 6, 4),
        title: "Production - Initial Machining Complete",
        description: "",
      },
      {
        date: new Date(2021, 7, 4),
        title: "Production - Anodization In Progress",
        description: "",
      },
      {
        date: new Date(2021, 7, 31),
        title: "Production - Case and Knob Manufacturing Complete",
        description:
          "all CNC parts ready, now to ship them off to the UK for final assembly!",
      },
      {
        date: new Date(2021, 8, 1),
        title: "Parts Packaging - Packing for Delivery to UK",
        description: "",
      },
      {
        date: new Date(2021, 8, 10),
        title: "Parts Delivery - Shipment to UK sent",
        description: "",
      },
      {
        date: new Date(2021, 9, 13),
        title: "Parts Delivery - Stuck at Port in China",
        description: "Port was blocked due to some port related incident",
      },
      {
        date: new Date(2021, 10, 24),
        title:
          "Parts Delivery - Unstuck, waiting for clearance at Port in China",
        description: "",
      },
      {
        date: new Date(2021, 11, 2),
        title: "Parts Delivery - Its on its way!",
        description: "",
      },
      {
        date: new Date(2021, 11, 13),
        title: "Parts Delivery - Passing Kazakhstan",
        description: "",
      },
      {
        date: new Date(2021, 11, 21),
        title: "Parts Delivery - Passing Volzhsky District, Russia",
        description: "",
      },
      {
        date: new Date(2021, 11, 28),
        title: "Parts Delivery - Passing Lithuania",
        description: "",
      },
      {
        date: new Date(2022, 1, 2),
        title: "ROTR manufacturing issue and delay announcement",
        description:
          "please visit: https://discord.com/channels/729010394756153384/801454709973843969/938402092501073981 for more information.",
      },
      {
        date: new Date(2022, 6, 4),
        title: "ROTR begins shipping from Mechboards UK to customers",
        description: "",
      },
      {
        date: new Date(2022, 6, 20),
        title: "GB extras now available on Mechboards UK",
        description: "",
      },
    ],
    url: "/rotr",
    externalUrl: "https://mechboards.co.uk/products/the-rotr-gb-extras",
    page: {
      content: (
        <div id="build-guide">
          <h3 className="font-bold mb-3 text-2xl font-title">Build Guide</h3>
          <a
            className="sm:text-sm text-xs bg-white border-2 border-gray-900 text-grey-700 hover:bg-gray-100 px-2 py-1 sm:px-3 sm:py-1 rounded-md sm:mr-4"
            href="https://docs.google.com/document/d/1nOHC0R_G6HR0cCJJgpj22CC5Q_K_o-7oYNo1CTE4pvk"
            target="_blank"
            rel="noreferrer noopener"
          >
            ROTR Guide and Tips
          </a>
        </div>
      ),
    },
  },
  [Product.ROTR_LITE]: {
    title: "ROTR LITE",
    description: {
      long: "ROTR's baby brother, the ROTR LITE, with the same ludicrously over-engineered encoder! the ROTR LITE has identical pcb and knob mechanism as the ROTR, but no aluminium case.",
      short: "The ROTRs baby brother, just as capable but in carbon fiber",
    },
    images: ["05", "01", "02", "03", "04"].map(
      augmentImagePath(Product.ROTR_LITE)
    ),
    model: <RotrLiteScene />,
    timeline: [],
    url: "/rotrLite",
    // externalUrl: "https://geekhack.org/index.php?topic=107104.0", // TODO: display IC links
  },
  [Product.CRBN]: {
    title: "CRBN",
    description: {
      long: "This little 40% ortho punches well above its weight and its price tag, weighing only 300g with its unique stacked carbon fiber construction it's still tough enough to survive life on the go or beating down someone that disses it. The switch plate accommodates several different configurations, an all 1u setup for use as a beastly macro pad, a 2u space bar, a 4u space bar, or even 2 2u keys in the lower center it's got all the configuration options you'd ever want in a 40%. All the components are through-hole so it's a great first keyboard kit to put together, with the huge number of microcontrollers that fit in the pro micro footprint like the Elite C or the nice nano you can equip your CRBN with USB C or even make it wireless! There's plenty of room between the PCB and the bottom plate for a huge lithium battery. This would be a vicious travel keyboard, a macro pad for productivity people, the first ortho for people who want to try ortho, there are or just a cute little keyboard to lend to people when they need one. Of course, it supports QMK and ZMK fully, so every key is individually remappable with as many layers as you desire.",
    },
    images: ["01", "02", "03", "04", "05", "06"].map(
      augmentImagePath(Product.CRBN)
    ),
    model: <ProductImage src="/images/crbn.png" />,
    timeline: [],
    url: "/crbn",
  },
  [Product.BT60]: {
    title: "BT60",
    description: {
      long: "The Polarity Works BT60, a simple, functional, hotswap wireless 60% that drops into most cases. The BT60 focuses on a polished experience rather than every bell and whistle. The Nordic Semiconductor BLE module delivers high performance and excellent range even in metal cases, and in conjunction with ZMK, it delivers the functionality to match. Zephyr's energy optimisation delivers fantastic battery life and with an easy-to-use UF2 bootloader making changes is as simple as drag and drop, no DFU drivers, no programming software, just double-tap reset or use the bootloader keycode and it pops up as a thumb drive. If you wish to make customisations ZMK's module-centric design lets you make changes without installing build environments or screwing around with path variables, just set up a ZMK-config GitHub repository, then build it in the cloud with GitHub actions and download the UF2 directly to your keyboard!",
      short:
        "Simple, minimalist, functional, the BT60 V1 is a hugely configurable board for everyone",
    },
    images: ["01", "02", "03", "04", "05", "06"].map(
      augmentImagePath(Product.BT60)
    ),
    model: <ProductImage src="/images/bt60.png" />,
    timeline: [
      {
        date: new Date(2021, 6, 15),
        title: "In Stock by Retailers",
        description: "",
      },
    ],
    url: "/bt60",
    externalUrl: "/bt60-resellers",
    card: {
      additionalContent: (
        <a
          href={KEYMAP_EDITOR_URL}
          target="_blank"
          className="opacity-50 mt-2 font-bold text-indigo-800 hover:underline hover:opacity-100"
        >
          <em>...or click here to customize your keymap</em>
        </a>
      ),
    },
    page: {
      button: (
        <Button
          variant={Variant.SECONDARY}
          href={KEYMAP_EDITOR_URL}
          label="Keymap Editor"
        />
      ),
      content: (
        <div id="build-guide">
          <h3 className="font-bold mb-3 text-2xl font-title">Build Guide</h3>
          <a
            className="sm:text-sm text-xs bg-white border-2 border-gray-900 text-grey-700 hover:bg-gray-100 px-2 py-1 sm:px-3 sm:py-1 rounded-md sm:mr-4"
            href="/products/bt60/BT60_Guide.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            BTCKP/BT60 Full Guide
          </a>
        </div>
      ),
    },
  },
  [Product.BTCKP]: {
    title: "BT CKP",
    description: {
      long: `Introducing the Polarity Works Common Keyboard Platform (CKP). While developing this we had one goal in mind, design the best series of wireless PCBs on the market. Starting from scratch we listened to feedback from the users of the BT60 V1, added extra features, more layouts, more sizes as well as the thinnest keyboard battery for near 100% case compatibility.
      Based around a common core design we now have 60,  65 and 75 versions, in both hotswap and soldered. As usual our soldered variants have a huge choice in layouts, we also have strengthened the pads to decrease chances you’ll rip a diode off as well as adding some spare fuses on the board to solder in place. We changed the fuse size to a larger one to facilitate easy replacement asa well as changing it for a resettable one so minimise the chances it needs replacement.
      A huge array of features have also been added. We spent a lot of time collating everything our customers wanted and everything we could possibly fit in the space we had and ended up adding a power switch in the reset well (which fails to always on so it’s not the end of the world if you break it off), an improved reset button that will be more reliable, 12 LED RGB underglow on all models, single colour key backlighting (solder your own leds on soldered, leds preinstalled on hotswap), a reset jumper so you can reset it from the top of the plate without needing to pull the keyboard apart if you dont have access to the button, a jumper you can cut to disable the charge led for stealth and support for up to 3 encoders on the 65 and 75 soldered variants. We also noticed that some users have had issues flashing the hotswap version onto the soldered board and vice versa. Now the firmwares are common between the versions, you can even flash a 75 firmware onto the 60 and it will work, but without the extra keys of course
      The Polarity Works CKP series of keyboard PCBs is set to totally shift the wireless PCB game.`,
      short:
        "The CKP series of boards just rewrote the rulebook on wireless pcbs, with every feature under the sun",
    },
    images: ["01", "02", "03", "04", "05", "06", "07", "08"].map(
      augmentImagePath(Product.BTCKP)
    ),
    model: <ProductImage src="/images/btckp.png" />,
    timeline: [
      {
        date: new Date(2021, 7, 5),
        title: "Initial Prototype",
        description: "",
      },
      {
        date: new Date(2021, 11, 14),
        title: "Production Batch Order Placed",
        description: "",
      },
      {
        date: new Date(2022, 2, 9),
        title: "Shipping To Retailers",
        description: "",
      },
      {
        date: new Date(2022, 2, 16),
        title: "Available in the EU",
        description: "",
      },
      {
        date: new Date(2022, 2, 27),
        title: "Available in the US",
        description: "",
      },
    ],
    url: "/btckp",
    externalUrl: "/bt-ckp-vendors",
    card: {
      additionalContent: (
        <a
          href={KEYMAP_EDITOR_URL}
          target="_blank"
          className="opacity-50 mt-2 font-bold text-indigo-800 hover:underline hover:opacity-100"
        >
          <em>...or click here to customize your keymap</em>
        </a>
      ),
    },
    page: {
      button: (
        <Button
          variant={Variant.SECONDARY}
          href={KEYMAP_EDITOR_URL}
          label="Keymap Editor"
        />
      ),
      content: (
        <div className="flex flex-col gap-8">
          <div id="build-guide">
            <h3 className="font-bold mb-3 text-2xl font-title">Build Guides</h3>
            <div className="flex gap-2 sm:mr-4">
              <a
                className="sm:text-sm text-xs bg-white border-2 border-gray-900 text-grey-700 hover:bg-gray-100 px-2 py-1 sm:px-3 sm:py-1 rounded-md"
                href="/products/btckp/BTCKP_Quick_Guide.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                BTCKP Quick Guide
              </a>
              <a
                className="sm:text-sm text-xs bg-white border-2 border-gray-900 text-grey-700 hover:bg-gray-100 px-2 py-1 sm:px-3 sm:py-1 rounded-md"
                href="/products/btckp/BTCKP_Firmware_Guide.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                BTCKP/BT60 Full Guide
              </a>
            </div>
          </div>
          <div id="compatibility-list">
            <h3 className="font-bold mb-3 text-2xl font-title">
              Case Compatibility List
            </h3>
            <a
              className="sm:text-sm text-xs bg-white border-2 border-gray-900 text-grey-700 hover:bg-gray-100 px-2 py-1 sm:px-3 sm:py-1 rounded-md sm:mr-4"
              href="https://docs.google.com/spreadsheets/u/2/d/13Lbbcl5IB17AtRAp6NcSjG65x_7uYAqBwbp5UiGhyJw/htmlview#"
              target="_blank"
              rel="noreferrer noopener"
            >
              Case Compatibility list for BTCKP series boards
            </a>
          </div>

          <div id="features-v1">
            <h3 className="font-bold mb-3 text-2xl font-title">Features</h3>
            <div className="grid sm:grid-cols-2 gap-6 text-gray-800 select-text">
              <div>
                <h4 className="font-semibold text-gray-700">
                  V1 Features ( BT60 + BTCKP )
                </h4>
                <ul>
                  <li>Bluetooth connectivity (Works in metal cases!)</li>
                  <li>Connect to up to 5 devices at once</li>
                  <li>Weeks of battery life (1200mAh battery included)</li>
                  <li>Almost universal case compatibility</li>
                  <li>Standard ANSI layout on hotswap</li>
                  <li>Solder pads for optional split back space</li>
                  <li>Kailh hotswap sockets</li>
                  <li>USB C</li>
                  <li>USB pins broken out</li>
                </ul>
              </div>

              <div>
                <h4 className="font-semibold text-gray-700">
                  V2 Extra Features ( BTCKP )
                </h4>
                <ul>
                  <li>The thinnest battery ever put in a keyboard</li>
                  <li>Even more layout options</li>
                  <li>Solder pads for split shift on hotswap</li>
                  <li>RGB underglow</li>
                  <li>Single colour per key lighting</li>
                  <li>Support for multiple rotary encoders</li>
                  <li>Power switch</li>
                  <li>Improved reset button</li>
                  <li>Solder jumper for turning off charging light</li>
                  <li>Common firmware between all variants</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  },
  [Product.SOON]: {
    title: "Something is afoot...",
    description: {
      long: "There have been rumblings over the past few months of something lurking in the deep, dark unknown. Some have taken to lurking around on our discord to glean any information they can, but no one is certain of anything yet...",
    },
    images: [],
    model: <div />,
    timeline: [],
    url: "",
    card: {
      additionalContent: (
        <a
          href={INTEREST_CHECK_FORM}
          target="_blank"
          className="opacity-50 mt-2 font-bold text-indigo-800 hover:underline hover:opacity-100"
        >
          <em>drop us a line if you're interested though!</em>
        </a>
      ),
    },
  },
};
