import { useEffect, useLayoutEffect, useState } from "react";
import { MOBILE_BREAKPOINT } from "../constants/layout";

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState<{
    height: number | null;
    width: number | null;
  }>({ height: null, width: null });

  const updateDimensions = () => {
    window.requestAnimationFrame(() => {
      setDimensions({ height: window.innerHeight, width: window.innerWidth });
    });
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return {
    ...dimensions,
    isDesktop: dimensions.width && dimensions.width > MOBILE_BREAKPOINT,
  };
};
