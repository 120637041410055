/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { CarbonFiber } from "../../constants/materials/CarbonFiber";

type GLTFResult = GLTF & {
  nodes: {
    Body2_4002: THREE.Mesh;
    Body2_44_1: THREE.Mesh;
    Body2_44_2: THREE.Mesh;
    Body2_44_3: THREE.Mesh;
    Body2_44_4: THREE.Mesh;
    Body2_44_5: THREE.Mesh;
    Body2_44_6: THREE.Mesh;
    Body2_44_7: THREE.Mesh;
    Body2_44_8: THREE.Mesh;
    ["STLB_ATF_81206_COLOR=�_5002"]: THREE.Mesh;
  };
  materials: {
    ["satin black.001"]: THREE.MeshStandardMaterial;
    ["carbon fiber"]: THREE.MeshPhysicalMaterial;
    ["brass fixings"]: THREE.MeshStandardMaterial;
    ["novelkeys cream"]: THREE.MeshStandardMaterial;
    brass: THREE.MeshPhysicalMaterial;
    ["black plastic"]: THREE.MeshStandardMaterial;
    ["shiny alu"]: THREE.MeshStandardMaterial;
    ["white plastic"]: THREE.MeshStandardMaterial;
    ["case black (deprecated).001"]: THREE.MeshStandardMaterial;
    ["kat_keycaps.001"]: THREE.MeshStandardMaterial;
  };
};

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials } = useGLTF(
    "/products/rotrLite/rotr-lite.gltf"
  ) as unknown as GLTFResult;
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0.0350996, 0, 0.056208]} scale={[0.001, 0.001, 0.001]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Body2_4002.geometry}
          material={materials["satin black.001"]}
        />
        <mesh castShadow receiveShadow geometry={nodes.Body2_44_1.geometry}>
          <CarbonFiber />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Body2_44_2.geometry}
          material={materials["brass fixings"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Body2_44_3.geometry}
          material={materials["novelkeys cream"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Body2_44_4.geometry}
          material={materials.brass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Body2_44_5.geometry}
          material={materials["black plastic"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Body2_44_6.geometry}
          material={materials["shiny alu"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Body2_44_7.geometry}
          material={materials["white plastic"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Body2_44_8.geometry}
          material={materials["case black (deprecated).001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["STLB_ATF_81206_COLOR=�_5002"].geometry}
        material={materials["kat_keycaps.001"]}
        position={[0.0354439, 0.0008269, -0.0623422]}
        scale={[0.001, 0.001, 0.001]}
      />
    </group>
  );
}
