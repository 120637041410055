import Link from "next/link";
import PW from "../icons/pw.svg";

export const Logo = () => {
  return (
    <span className="font-black text-gray-800 cursor-pointer dark:text-gray-300">
      <PW className="h-6 sm:h-8" />
    </span>
  );
};

export const SmlLogo = ({ inverted }: { inverted?: boolean }) => {
  return (
    <Link href="/">
      <a
        title="homepage"
        className={`
        fixed z-50 top-6 left-5
        text-2xl font-black tracking-widest font-title
        transition-colors duration-500
        hover:text-blue-600
        ${inverted ? "text-gray-100" : "text-gray-800"}`}
      >
        <PW className="h-4 sm:h-6" />
      </a>
    </Link>
  );
};
