/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    kat_keycaps: THREE.Mesh;
    knob: THREE.Mesh;
    ["rotr-body"]: THREE.Mesh;
    screws: THREE.Mesh;
    usbc: THREE.Mesh;
  };
  materials: {
    kat_keycaps: THREE.MeshStandardMaterial;
    alu_knob: THREE.MeshStandardMaterial;
    ["satin black"]: THREE.MeshStandardMaterial;
    ["brushed alu"]: THREE.MeshStandardMaterial;
  };
};

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials } = useGLTF(
    "/products/rotr/rotr.gltf"
  ) as GLTFResult;
  return (
    <group ref={group} scale={10} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.kat_keycaps.geometry}
        material={materials.kat_keycaps}
        position={[-0.0983676, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.knob.geometry}
        material={materials.alu_knob}
        position={[0.0328892, 0, 0.0455154]}
        scale={0.001}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["rotr-body"].geometry}
        material={materials["satin black"]}
        position={[0.0328892, 0, 0.0455154]}
        scale={0.001}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.screws.geometry}
        material={nodes.screws.material}
        position={[0.0328892, 0, 0.0455154]}
        scale={0.001}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.usbc.geometry}
        material={nodes.usbc.material}
        position={[0.0330049, 0, 0.0457178]}
        scale={0.001}
      />
    </group>
  );
}
