import { AdaptiveDpr, Html, Stats, Stage, Environment, ContactShadows } from "@react-three/drei";
import React, { Suspense } from "react";
import { Loading } from "../../components/Homepage/Loading";
import { useDimensions } from "../../components/useDimensions";
import { Scene3D } from "../../templates/Scene3D";
import RotrLite from "./RotrLite";

export const RotrLiteScene: React.FC = () => {
  const { isDesktop } = useDimensions();
  return (
    <Scene3D
      pannable={false}
      camera={{ position: [-2, 0, 3.5], zoom: isDesktop ? 1 : 0.8 }}
      controls={{ limit: { down: 0.6, up: 0.2 } }}
    >
      {/* <Stats /> */}
      <Suspense
        fallback={
          <Html className="w-full h-full">
            <Loading />
          </Html>
        }
      >
        <RotrLite scale={[10, 10, 10]} rotation={[Math.PI * 0.4, 0, Math.PI * 0.2]} />
        <ContactShadows
          rotation-x={Math.PI / 2}
          position={[0, -0.6, 0]}
          opacity={0.25}
          width={10}
          height={10}
          blur={1.5}
          far={0.8}
        />

        <Environment files="./hdri/potsdamer_platz_1k.hdr" />
      </Suspense>

      <AdaptiveDpr pixelated />
    </Scene3D>
  );
};
